import { current } from "@reduxjs/toolkit";
import {
    UPDATE_GPS_LOCATION,
    UPDATE_CURRENT_USER_LOCATION
    
  } from "../store/types";
  
  const INITIAL_STATE = {
    location: null,
    currentUserLocation:null,
    error:false
  }
export const gpsreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_GPS_LOCATION:
            return {
                location: action.payload && action.payload.lat? action.payload : null,
                error: action.payload && action.payload.lat? false : true
            };
        case UPDATE_CURRENT_USER_LOCATION:
            return{
                currentUserLocation: action.payload && action.payload.lat? action.payload : null,
                // error: action.payload && action.payload.lat? false : true 
            }
        default:
            return state;
    }
};