import {
    SAVE_CALL_DATA,
    FETCH_CALL_DATA,
    FETCH_CALL_DATA_SUCCESS,
    FETCH_CALL_DATA_FAILED,
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { get, onValue, push } from "firebase/database";

export const saveCallData = (callData:any) => async (dispatch:any) => {

    const {
        callsRef
    } = firebase;
    if(callsRef){
        try {
            const callRef = callsRef(callData.bookingId);
            await push(callRef ,callData);
            dispatch({
                type: SAVE_CALL_DATA,
                payload: callData,
            });
        } catch (error) {
            console.error("Error saving call data:", error);
        }
    }
};

export const fetchCallData = (bookingId:any) => async (dispatch:any) => {
    dispatch({ type: FETCH_CALL_DATA });
    const {
        callsRef
    } = firebase;
    if(callsRef){
            try {
                const callRef = callsRef(bookingId);
                onValue(callRef, (snapshot) => {
                    const data = snapshot.val();
                    dispatch({
                        type: FETCH_CALL_DATA_SUCCESS,
                        payload: data,
                    });
                }, (error) => {
                    console.error("Error fetching call data:", error);
                    dispatch({
                        type: FETCH_CALL_DATA_FAILED,
                        payload: error.message,
                    });
                });
            } catch (error:any) {
                dispatch({
                    type: FETCH_CALL_DATA_FAILED,
                    payload: error.message,
                });
            }
    }
};

