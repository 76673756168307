import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Modal, Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "components/CustomButtons/Button.js";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { Autocomplete, MenuItem } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import BlockIcon from "@mui/icons-material/Block";
import { colors } from "../components/Theme/WebTheme";
import moment from "moment/min/moment-with-locales";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submit3: {
    width: "100%",
    borderRadius: 3,
    marginTop: 2,
    padding: 4,
  },
  paper: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 100,
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  filtersContainer: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  tableContainer: {
    flex: 1,
    overflow: 'auto',
  },
  statusButton: {
    borderRadius: '20px',
    padding: '8px 16px',
    minWidth: '120px',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    textTransform: 'none',
    '&.pending': {
      backgroundColor: theme.palette.warning.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      }
    },
    '&.approved': {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      }
    }
  },
  confirmDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '15px',
      padding: '10px',
    }
  }
}));

export default function CarsList() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const userdata = useSelector((state) => state.usersdata);
  const auth = useSelector((state) => state.auth);
  const [drivers, setDrivers] = useState([]);
  const { updateUserCar, editCar } = api;
  const [driversObj, setDriversObj] = useState();
  const [fleetMapObj, setFleetMapObj] = useState([]);
  const [data, setData] = useState([]);
  const cartypes = useSelector((state) => state.cartypes);
  const carlistdata = useSelector((state) => state.carlistdata);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [cars, setCars] = useState({});
  const [role, setRole] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState("all");
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    car: null,
    newStatus: null
  });

  useEffect(() => {
    if (role !== "driver" && userdata.users) {
      let arr = userdata.users.filter(
        (user) =>
          user.usertype === "driver" &&
          ((role === "fleetadmin" &&
            user.fleetadmin &&
            user.fleetadmin === auth.profile.uid) ||
            role === "admin")
      );
      let obj = {};
      let obj2 = {};
      let arr2 = [];
      for (let i = 0; i < arr.length; i++) {
        let user = arr[i];
        arr2.push({
          id: user.id,
          desc:
            user.firstName +
            " " +
            user.lastName +
            " (" +
            (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") +
            ") " +
            (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
        });
        obj[user.id] =
          user.firstName +
          " " +
          user.lastName +
          " (" +
          (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") +
          ") " +
          (settings.AllowCriticalEditsAdmin ? user.email : "Hidden");
        obj2[user.id] = user.fleetadmin ? user.fleetadmin : null;
      }
      setDrivers(arr2);
      setDriversObj(obj);
      setFleetMapObj(obj2);
    }
  }, [
    userdata.users,
    settings.AllowCriticalEditsAdmin,
    role,
    auth.profile.uid,
  ]);

  useEffect(() => {
    if (cartypes.cars) {
      let obj = {};
      cartypes.cars
        .sort((a, b) => a.pos - b.pos)
        .map((car) => (obj[car.name] = car.name));
      setCars(obj);
    }
  }, [cartypes.cars]);

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  useEffect(() => {
    if (carlistdata.cars) {
      let filteredData = carlistdata.cars;

      if (fromDate || toDate) {
        filteredData = filteredData.filter((car) => {
          const carDate = new Date(car.createdAt).setHours(0, 0, 0, 0);
          const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
          const to = toDate ? new Date(toDate).setHours(0, 0, 0, 0) : null;

          if (from && carDate < from) return false;
          if (to && carDate > to) return false;
          return true;
        });
      }

      if (approvalStatus !== "all") {
        filteredData = filteredData.filter(
          (car) => car.approved === (approvalStatus === "approved")
        );
      }

      setData(filteredData);
    } else {
      setData([]);
    }
  }, [carlistdata.cars, fromDate, toDate, approvalStatus]);

  const handleStatusChange = (car) => {
    if (!car) return;
    
    setConfirmDialog({
      open: true,
      car: car,
      newStatus: !car.approved
    });
  };

  const confirmStatusChange = () => {
    if (!confirmDialog.car) return;
    
    const updatedCar = { 
      ...confirmDialog.car, 
      approved: confirmDialog.newStatus 
    };
    if(updatedCar.approved){
      dispatch(
        updateUserCar(updatedCar.driver, { carApproved: true })
      );
    }
    else{
      dispatch(
        updateUserCar(updatedCar.driver, { 
          driverActiveStatus:false, 
          carApproved: false 
        })
      );
    }
    dispatch(editCar(updatedCar, "Update"));
    setConfirmDialog({ open: false, car: null, newStatus: null });
  };

  const columns = [
    {
      title: t("approved"),
      field: "approved",
      type: "boolean",
      editable: false,
      render: (rowData) => (
        <Tooltip title={t(rowData.approved ? "change_to_pending" : "change_to_approved")}>        
          <Button
            variant="contained"
            color={rowData.approved ? "success" : "warning"}
            className={classes.statusButton}
            onClick={() => handleStatusChange(rowData)}
            startIcon={rowData.approved ? <DoneAllIcon /> : <BlockIcon />}
          >
            {rowData.approved ? t("approved") : t("pending")}
          </Button>
        </Tooltip>
      ),
    },
    {
      title: t("createdAt"),
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("driver_email"),
      field: "email",
      editable: "never",
      cellStyle: { textAlign: "center" },
      render: (rowData) => rowData.email || null,
    },
    {
      title: t("driver_mobile"),
      field: "mobile",
      editable: "never",
      cellStyle: { textAlign: "center" },
      render: (rowData) => rowData.mobile || null,
    },
    {
      title: t("car_type"),
      field: "carType",
      lookup: cars,
    },
    {
      title: t("vehicle_reg_no"),
      field: "vehicleNumber",
    },
    {
      title: t("vehicle_model_name"),
      field: "vehicleMake",
    },
    {
      title: t("vehicle_model_no"),
      field: "vehicleModel",
    },
  ];

  return carlistdata.loading ? (
    <CircularLoading />
  ) : (
    <div ref={rootRef} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Fixed Header and Filters */}
      <div className={classes.headerContainer}>
        {/* Filters */}
        <div className={classes.filtersContainer}>
          <TextField
            label={t("from_date")}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <TextField
            label={t("to_date")}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setToDate(e.target.value)}
          />
          <TextField
            select
            label={t("approval_status")}
            value={approvalStatus}
            onChange={(e) => setApprovalStatus(e.target.value)}
            style={{ width: 200 }}
          >
            <MenuItem value="all">{t("all")}</MenuItem>
            <MenuItem value="approved">{t("approved")}</MenuItem>
            <MenuItem value="pending">{t("pending")}</MenuItem>
          </TextField>
        </div>
      </div>

      {/* Scrollable Table Container */}
      <div className={classes.tableContainer}>
        <MaterialTable
          title={t("cars")}
          columns={columns}
          data={data}
          options={{
            exportButton: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData && rowData.tableData.id % 2 === 0 ? "#f9f9f9" : "#FFF",
            }),
            headerStyle: {
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
              zIndex: 1,
            },
            maxBodyHeight: 'calc(100vh - 200px)', // Adjust this value based on your header height
          }}
        />
      </div>

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, car: null, newStatus: null })}
        className={classes.confirmDialog}
      >
        <DialogTitle>
          {confirmDialog.car?.approved ? t("Disapprove") : t("Approve")}
        </DialogTitle>
        <DialogContent>
          {confirmDialog.car && (
            <Typography>
              {confirmDialog.car.approved ? (
                t("Revert to pending status?", {
                  vehicle: confirmDialog.car.vehicleNumber
                })
              ) : (
                t("Approve Car?", {
                  vehicle: confirmDialog.car.vehicleNumber
                })
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setConfirmDialog({ open: false, car: null, newStatus: null })}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button 
            onClick={confirmStatusChange}
            color="primary"
            variant="contained"
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
