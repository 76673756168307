import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoading from '../components/CircularLoading';
import { Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { fetchUserById } from 'common/src/actions/usersactions';
import UserInfo from '../components/UserInfo';
import VehicleInfo from '../components/VehicleInfo';
import BankInfo from '../components/BankInfo';
import DocumentPreview from '../components/DocumentPreview';

export default function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState<null | any>(null);
  const [loading, setLoading] = useState(true);
  const selectedUser = useSelector((state: AppState) => state.usersdata.selectedUser);
  const loadingSelecteUser = useSelector((state: AppState) => state.usersdata.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadUserDetails = async () => {
      if (selectedUser) {
        setUser(selectedUser);
        setLoading(false);
        return;
      }
      try {
        dispatch(fetchUserById(userId));
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    loadUserDetails();


    return ()=>{
        setUser(null);
        setLoading(true);
    }
  }, [dispatch, userId, selectedUser]);

  useEffect(() => {
    setUser(selectedUser);
    setLoading(false);

    
  }, [selectedUser]);

  useEffect(() => {
    setLoading(loadingSelecteUser);
  }, [loadingSelecteUser]);

  if (loading) {
    return <CircularLoading />;
  }

  if (!user && !loading) {
    return <Typography variant="h6">User not found</Typography>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <UserInfo user={user} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <VehicleInfo user={user} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <BankInfo user={user} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <DocumentPreview user={user} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
} 
