import { firebase } from "../config/configureFirebase";
import store from "../store/store";

export const RequestPushMsg = (token, data) => {
  const { config } = firebase;

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/send_notification`;

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
      ...data,
    }),
  })
    .then((response) => {
      console.log("notification sent");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const NotifyDevelopersTeam = (data) => {
  // if data  is not a json object return error
  if (typeof data !== "object") {
    return false;
  }
  const { config } = firebase;

  const settings = store.getState().settingsdata.settings;

  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/notify_developers`;

  console.log("=================>notify developers url", url);
  console.log("====================>notify developers data", data);

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log("notification sent");
    })
    .catch((error) => {
      console.log(error);
    });
};


